<template>
  <a-modal :visible="shown"
           :title="title"
           :confirm-loading="loading"
           :mask-closable="true"
           :destroy-on-close="true"
           :after-close="reset"
           width="400px"
           @ok="save()"
           @cancel="close()">
    <div class="flex content">
      <a-input-number v-model="score"
                      :min="0"
                      :allow-clear="true"
                      :formatter="value => `${value}`.replace(/\D*/g, '')"
                      :parser="value => `${value}`.replace(/\D*/g, '')"
                      placeholder="请输入大于0整数"
                      style="width: 240px;"/>
      <span class="ml-10">分</span>
    </div>

    <div class="empty">
      需要删除评分时，清空输入的分数，直接提交
    </div>
  </a-modal>
</template>

<script>
import { toGradeWork } from '../api/config';

export default {
  name: 'gradeModal',
  inject: ['activityId'],
  computed: {
    title() {
      return '请输入评价分数（可更改）';
    },
  },
  data() {
    return {
      loading: false,
      shown: false,

      id: '',
      score: undefined,
    };
  },
  methods: {
    show({ id, score } = {}) {
      this.id = id;
      this.score = (score !== undefined && score !== null) ? +score : undefined;

      this.shown = true;
    },
    close() {
      this.shown = false;
    },
    reset() {
      this.id = '';
      this.score = 0;
    },

    async save() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      const params = {
        upload_works_user_id: this.id,
      };
      if (this.score !== undefined && this.score !== null) {
        params.score = this.score;
      }
      const data = await toGradeWork(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }

      this.$message.success(data?.message || '保存成功');
      this.$emit('saved', {
        id: this.id,
        score: this.score,
      });
      this.close();
    },
  },
};
</script>

<style scoped lang="less">
.empty {
  font-size: 14px;
  color: #BFBFBF;
}
</style>
