<template>
  <a-cascader v-model="id"
              v-bind="$attrs"
              :options="list"
              placeholder="请选择标题"
              change-on-select
              @change="onChange"/>
</template>

<script>
import { getTitleListForSelect } from '../api/select';

export default {
  name: 'selectTitle',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: Array },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.id = val;
      },
    },
  },
  data() {
    return {
      id: undefined,

      list: [],
    };
  },
  created() {
    this.getTitleListForSelect();
  },
  methods: {
    async getTitleListForSelect() {
      const data = await getTitleListForSelect();

      this.list = (data?.data || []).map((level1) => {
        level1.value = level1.id;
        level1.label = level1.title;

        if (!level1.children) {
          level1.children = [];
        }
        level1.children.forEach((level2) => {
          level2.value = level2.id;
          level2.label = level2.secondary_title;
        });

        return level1;
      });
    },

    onChange(evt) {
      this.$emit('change', this.id, evt);
    },
  },
};
</script>

<style scoped lang="less">

</style>
