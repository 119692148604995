<template>
  <div>
    <app-loading-status :non-data="!(list && list.length)" :is-loading="loading"/>

    <div class="ph-20">
      <a-collapse v-if="list && list.length" v-model="activeKey">
        <a-collapse-panel v-for="item of list" :key="item.id.toString()" :header="null">
          <div slot="extra" class="flex space-between panel-extra">
            <div class="flex flex-start flex-wrap">
              <span class="flex-none mr-20">{{ item.sort }}阶段：{{ item.activity_module_name }}</span>
            </div>

            <div class="flex flex-none" @click.stop="">
              <a-button type="link" @click="addOpusUpload(item)">添加上传项</a-button>
            </div>
          </div>

          <template v-if="item.children && item.children.length">
            <a-table :columns="columns"
                     :data-source="item.children"
                     :pagination="false"
                     :loading="loading"
                     :scroll="{ x: '100%' }"
                     row-key="id"
                     class="mb-10 table small-cell-table">
              <template slot="action" slot-scope="text, record">
                <a-button type="link" @click="editOpusUpload(record, item)">编辑</a-button>
                <a-button type="link" @click="changeOpusUploadStatus(record)">
                  {{ +record.status === 1 ? '关闭' : '开启' }}
                </a-button>
                <a-button type="link" @click="deleteOpusUpload(record)">删除</a-button>
              </template>
            </a-table>
          </template>
          <template v-else>
            <div class="row row-center">暂无数据</div>
          </template>
        </a-collapse-panel>
      </a-collapse>
    </div>

    <EditOpusUploadModal ref="editOpusUploadModalRef"
                         @saved="updateOpusUploadForce"/>
  </div>
</template>

<script>
import moment from 'moment';
import {
  getOpusList,
  updateOpusStatus,
  deleteOpus,
} from '../api/config';

import EditOpusUploadModal from '../components/editOpusUploadModal.vue';

const columns = [
  {
    width: '180px',
    align: 'center',
    dataIndex: 'post_name',
    key: 'post_name',
    title: '岗位',
  },
  {
    width: '180px',
    align: 'center',
    dataIndex: 'primary_title',
    key: 'primary_title',
    title: '入口名称（1级标题）',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'secondary_title',
    key: 'secondary_title',
    title: '2级标题',
    customRender: (text) => text || '--',
  },
  {
    width: '90px',
    align: 'center',
    dataIndex: 'file_type_name',
    key: 'file_type_name',
    title: '类型',
  },
  {
    width: '140px',
    align: 'center',
    dataIndex: 'end_time',
    key: 'end_time',
    title: '截止时间',
    customRender: (text) => text ? moment(text * 1000).format('YYYY-MM-DD HH:mm') : '--',
  },
  {
    width: '60px',
    align: 'center',
    dataIndex: 'status_name',
    key: 'status_name',
    title: '状态',
  },
  {
    width: '160px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    fixed: 'right',
  },
];

export default {
  name: 'opus',
  components: {
    EditOpusUploadModal,
  },
  inject: ['activityId'],
  data() {
    return {
      loading: false,

      activeKey: [],

      columns,
      list: [],
    };
  },
  created() {
    this.getOpusList();
  },
  methods: {
    async getOpusList() {
      this.loading = true;
      const data = await getOpusList({
        activity_id: this.activityId,
      }).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取数据失败');
        return;
      }
      this.list = (data?.data || []).map((i) => ({
        ...i,
        id: i.activity_module_id,
        children: i.activity_link_list,
      }));
      this.activeKey = this.list.map((i) => i.id);
    },

    addOpusUpload(record) {
      this.showEditOpusUploadModal(undefined, record);
    },
    editOpusUpload(item, record) {
      this.showEditOpusUploadModal(item.id, record);
    },
    showEditOpusUploadModal(id, record) {
      if (this.$refs.editOpusUploadModalRef?.show) {
        this.$refs.editOpusUploadModalRef.show({
          id,
          stageId: record?.id,
          record,
        });
      }
    },
    updateOpusUploadForce() {
      this.getOpusList();
    },
    updateOpusUpload(params) {
      const [item] = this.list.flatMap(
        (i) => i.children?.length ? i.children : [],
      ).filter(
        (i) => i.id === params.id,
      ) || [];

      if (!item?.id) {
        this.getOpusList();
        return;
      }

      Object.keys(params).forEach((key) => {
        item[key] = params[key];
      });
    },

    async changeOpusUploadStatus(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await updateOpusStatus({
        id: record.id,
        status: (record.status + 1) % 2,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '状态修改失败');
        return;
      }
      this.$message.success(data?.message || '状态修改成功');
      this.list.forEach((item) => {
        if (item.children?.length) {
          item.children.forEach((i) => {
            if (i.id === record.id) {
              i.status = (record.status + 1) % 2;
              i.status_name = i.status ? '开启' : '关闭';
            }
          });
        }
      });
    },

    deleteOpusUpload(record) {
      this.$confirm({
        title: '提示',
        content: `确认删除上传入口 "${record.primary_title}" ？`,
        onOk: () => this.confirmDeleteOpusUpload(record),
      });
    },
    async confirmDeleteOpusUpload(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await deleteOpus({ id: record.id }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '删除失败');
        return;
      }
      this.$message.success(data?.message || '删除成功');
      this.list.forEach((item) => {
        if (item.children?.length) {
          const index = item.children.findIndex((i) => i.id === record.id);
          if (index > -1) {
            item.children.splice(index, 1);
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
::v-deep .ant-collapse-extra {
  float: none !important;
}
</style>
