<template>
  <a-form :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          class="form">
    <a-form-item label="选择课程">
      <SelectCourse v-decorator="['course_ids', decorator.course_ids]"
                    name="course_ids"
                    @change="courseChange"/>
    </a-form-item>
    <a-form-item label="考试名称">
      <SelectCourseTask v-decorator="['task_ids', decorator.task_ids]"
                        :course-ids="courseIds"
                        course-task-type="testpaper"
                        name="task_ids"/>
    </a-form-item>

    <a-form-item label="选择时间段">
      <a-range-picker v-decorator="['dateRange', decorator.dateRange]"
                      name="dateRange"/>
    </a-form-item>

    <a-form-item label="组织机构">
      <app-select-org v-decorator="['org_ids', decorator.org_ids]"
                      name="org_ids"
                      :show-select-all-org="true"/>
    </a-form-item>

    <a-form-item label="店型">
      <SelectShopType v-model="shopType"
                      name="shopType"/>
    </a-form-item>

    <a-form-item label="岗位">
      <app-select-job v-decorator="['post_ids', decorator.post_ids]"
                      name="post_ids"/>
    </a-form-item>

    <a-form-item v-if="false" label="memory">
      <a-input allow-clear v-model="memory" placeholder="memory"/>
    </a-form-item>

    <a-form-item label=" " :colon="false">
      <a-button v-if="false" type="primary" :loading="loading" @click="exportCourseLearnInfoTEST">
        导出查询结果TEST
      </a-button>
      <a-button v-if="false" type="primary" :loading="loading" @click="exportCourseLearnInfo">
        导出查询结果
      </a-button>
      <a-button type="primary" :loading="loading" @click="getPersonalQuizDownloadUrl">
        导出查询结果
      </a-button>
      <a-button @click="reset">重置</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import {
  exportPersonalQuiz,
  getPersonalQuizDownloadUrl,
  exportPersonalQuizTEST,
} from '../api/statistics';

import SelectCourse from '../../../statistics/participation/components/SelectCourse.vue';
import SelectShopType from '../../../../components/select/SelectShopType.vue';
import SelectCourseTask from '../../../../components/select/SelectCourseTask.vue';

const decorator = {
  course_ids: {
    rules: [{ required: true, message: '请选择课程' }],
  },
  task_ids: {
    rules: [{ required: true, message: '请选择考试' }],
  },
  dateRange: {
    rules: [{ required: false, message: '请选择时间段' }],
  },
  org_ids: {
    rules: [{ required: true, message: '请选择组织机构' }],
  },
  // todo 选择店型组件不支持 v-decorator ...
  // shopType: {
  //   rules: [{ required: false, message: '请选择店型' }],
  // },
  post_ids: {
    rules: [{ required: false, message: '请选择岗位' }],
  },
};

export default {
  name: 'personalQuiz',
  components: {
    SelectCourse,
    SelectShopType,
    SelectCourseTask,
  },
  data() {
    return {
      loading: false,

      decorator,

      courseIds: '',
      shopType: { store_state: '1' },
      id: '',

      memory: '',
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {});
  },
  methods: {
    setDecorator(data = {}) {
      const values = {};
      Object.keys(this.decorator).forEach((key) => {
        if (data[key] !== undefined && data[key] !== null) {
          values[key] = data[key];
        }
      });
      this.form.setFieldsValue(values);
    },

    validate() {
      return new Promise((resolve, reject) => {
        this.form.validateFields((error, values) => {
          if (!error) {
            const params = {
              ...values,
            };

            if (params.dateRange?.[0]) {
              params.start_time = params.dateRange[0].startOf('days').format('YYYY-MM-DD HH:mm:ss');
            }
            if (params.dateRange?.[1]) {
              params.end_time = params.dateRange[1].endOf('days').format('YYYY-MM-DD HH:mm:ss');
            }

            params.store_state = this.shopType?.store_state || '';
            params.store_type = this.shopType?.store_type || '';
            params.operation_state = this.shopType?.operation_state || '';
            params.store_age = this.shopType?.store_age || '';

            params.down = 1;
            params.memory = 4; // 此值固定为 4 或者 8，接口会返回文件下载地址，否则直接返回文件二进制流

            delete params.dateRange;
            delete params.shopType;

            resolve(params);
          } else {
            reject(error);
          }
        });
      });
    },

    async getPersonalQuizDownloadUrl() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      this.validate().then((params) => {
        getPersonalQuizDownloadUrl(params).then((data) => {
          if (!data || data.error_code) {
            this.$message.error(data?.message || '下载失败');
            return;
          }

          const filePath = data?.data?.filepath || '';
          if (filePath) {
            this.$message.success(data?.message || '下载成功');
            window.location.href = filePath;
          }
        }).catch((err) => {
          this.$message.error(err?.response?.message || '下载失败');
        }).finally(() => {
          this.loading = false;
        });
      }).catch(() => {
        this.loading = false;
      });
    },

    exportCourseLearnInfo() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      this.validate().then((params) => {
        exportPersonalQuiz(params).then(() => {
          this.$message.success('下载成功');
        }).catch((err) => {
          this.$message.error(err?.response?.message || '下载失败');
        }).finally(() => {
          this.loading = false;
        });
      }).catch(() => {
        this.loading = false;
      });
    },

    exportCourseLearnInfoTEST() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      exportPersonalQuizTEST({
        name: 'cs9-1-1',
        t: 9,
        memory: this.memory,
      }).then(() => {
        this.$message.success('下载成功');
      }).catch((err) => {
        this.$message.error(err?.response?.message || '下载失败');
      }).finally(() => {
        this.loading = false;
      });
    },

    reset() {
      const item = {};
      Object.keys(this.decorator).forEach((key) => {
        if (key === 'shopType') {
          item[key] = { store_state: '1' };
        } else if (key === 'dateRange') {
          item[key] = [undefined, undefined];
        } else {
          item[key] = '';
        }
      });
      this.shopType = { store_state: '1' };
      this.setDecorator(item);
    },

    courseChange(ids, items) {
      this.courseIds = ids || '';
      this.setDecorator({
        post_ids: Array.from(
          new Set(
            items.map((i) => i.tags).join(',').split(',').filter((i) => !!i),
          ),
        ).join(','),
      });
    },
  },
};
</script>

<style scoped lang="less">
.form {
  max-width: 800px;
}
</style>
