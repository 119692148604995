<template>
  <a-form :form="form"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }"
          layout="inline">

    <a-form-item v-for="(item, index) in list"
                 :key="index"
                 :label="item.label"
                 class="form-item">
      <a-upload v-decorator="[item.id, decorator[item.id]]"
                :multiple="false"
                accept=".jpg, .jpeg, .png"
                list-type="picture-card"
                :disabled="uploading[item.id]"
                :show-upload-list="{
                  showPreviewIcon: true,
                  showRemoveIcon: true,
                }"
                :custom-request="(evt) => customRequest(item.id, evt)"
                :beforeUpload="beforeUpload"
                :remove="(evt) => onRemove(item.id, evt)"
                @preview="onPreview">
        <div v-if="!item.url">
          <a-icon :type="loading ? 'loading' : 'plus'"/>
          <div class="ant-upload-text">上传</div>
        </div>
      </a-upload>
    </a-form-item>

    <a-form-item label=" " :colon="false" class="button-group">
      <a-button type="primary" @click="onSubmit">保存</a-button>
      <a-button type="default" @click="onCancel">取消</a-button>
    </a-form-item>

    <app-image-viewer ref="appImageViewerRef"
                      :url="previewImageUrl"
                      :hide-placeholder="true"
                      @close="hidePreview"/>
  </a-form>
</template>

<script>
import {
  uploadFileToPublic,
} from '@/api/Common';
import {
  getPictureDetail,
  setPictureDetail,
} from '../api/config';

const decorator = {
  index_page_pic_ids: {
    formLabel: '"首页"头图',
    valuePropName: 'fileList',
    getValueFromEvent: (e) => Array.isArray(e) ? e : (e && e.fileList),
    rules: [{ required: true, message: '请上传"首页"头图' }],
  },
  upload_page_pic_ids: {
    formLabel: '"上传页"头图',
    valuePropName: 'fileList',
    getValueFromEvent: (e) => Array.isArray(e) ? e : (e && e.fileList),
    rules: [{ required: true, message: '请上传"上传页"头图' }],
  },
  test_page_pic_ids: {
    formLabel: '"考试页"头图',
    valuePropName: 'fileList',
    getValueFromEvent: (e) => Array.isArray(e) ? e : (e && e.fileList),
    rules: [{ required: true, message: '请上传"考试页"头图' }],
  },
  rank_page_pic_ids: {
    formLabel: '"排行榜页"头图',
    valuePropName: 'fileList',
    getValueFromEvent: (e) => Array.isArray(e) ? e : (e && e.fileList),
    rules: [{ required: true, message: '请上传"排行榜页"头图' }],
  },
  honor_page_pic_ids: {
    formLabel: '"荣耀展台二级页面"头图',
    valuePropName: 'fileList',
    getValueFromEvent: (e) => Array.isArray(e) ? e : (e && e.fileList),
    rules: [{ required: true, message: '请上传"排行榜页"头图' }],
  },
};
const uploadingPic = {};
const list = Object.keys(decorator).map((key) => {
  uploadingPic[key] = false;
  return {
    id: key,
    label: decorator[key].formLabel,
    url: '',
  };
});
const decoratorMap = {
  index_page: 'index_page_pic_ids',
  upload_page: 'upload_page_pic_ids',
  test_page: 'test_page_pic_ids',
  rank_page: 'rank_page_pic_ids',
  honor_page: 'honor_page_pic_ids',
};

export default {
  name: 'configPicture',
  inject: ['activityId'],
  computed: {
    uploading() {
      return Object.keys(this.uploadingPic).some((key) => this.uploadingPic[key]);
    },
  },
  data() {
    return {
      loading: false,
      uploadingPic,

      previewImageUrl: '',

      decorator,
      list,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {});
  },
  created() {
    this.getPictureDetail();
  },
  methods: {
    async getPictureDetail() {
      this.loading = true;

      const data = await getPictureDetail({
        activity_id: this.activityId,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取数据失败');
        return;
      }
      const result = {};
      Object.keys(data?.data).forEach((key) => {
        result[decoratorMap[key]] = data.data[key];
      });
      this.setDecorator(result);
    },

    setDecorator(data = {}) {
      const values = {};
      Object.keys(this.decorator).forEach((key) => {
        if (!data[key]?.length) {
          return;
        }
        const item = data[key][0] || {};
        values[key] = [{
          uid: item.pic_file_id,
          name: `${item.pic_url.split('/').reverse()[0]}`,
          status: 'done',
          url: item.pic_url,
          file_resource_id: item.pic_file_id,
        }];
        this.list.forEach((i) => {
          if (i.id === key) {
            i.url = item.pic_url || '';
          }
        });
      });

      this.form.setFieldsValue(values);
    },

    beforeUpload(file) {
      const types = ['jpg', 'jpeg', 'png'];
      const type = file.type;
      const size = file.size;

      return new Promise((resolve, reject) => {
        if (types.every((t) => !type.includes(t))) {
          this.$message.info('图片仅支持 .jpg .jpeg .png 格式，请重新选择图片');
          reject('ImageTypeError');
        } else if (size > 10 * 1024 * 1024) {
          this.$message.info('图片大小不能超过 10M，请重新选择图片');
          reject('ImageSizeError');
        } else {
          resolve();
        }
      });
    },
    async customRequest(field, { file, onSuccess, onError }) {
      if (this.uploadingPic[field]) {
        onError('Loading');
        return;
      }
      this.uploadingPic[field] = true;

      const params = new FormData();
      params.append('file', file);
      params.append('source', 'common');

      const data = await uploadFileToPublic(params);
      if (!data || data.error_code) {
        onError(data);
        this.$message.error(data?.message || '图片上传失败');
        return;
      }
      onSuccess();
      this.$message.success(data.message || '图片上传成功');

      this.uploadingPic[field] = false;
      file.url = data.data.url;
      file.file_resource_id = data.data.file_resource_id;
      this.list.forEach((i) => {
        if (i.id === field) {
          i.url = file.url;
        }
      });
    },

    onCancel() {
      this.getPictureDetail();
    },
    onSubmit() {
      this.form.validateFields((error, values) => {
        if (!error) {
          const params = {};

          Object.keys(this.decorator).forEach((key) => {
            if (values[key]?.length) {
              const img = [...values[key]].reverse()[0];
              params[key] = img.file_resource_id || img.originFileObj?.file_resource_id || '';
            }
          });

          this.save(params);
        }
      });
    },
    async save(params) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await setPictureDetail({
        activity_id: this.activityId,
      }, params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data?.message || '保存成功');
    },

    onRemove(field) {
      this.list.forEach((i) => {
        if (i.id === field) {
          i.url = '';
        }
      });

      return true;
    },
    onPreview(file) {
      const url = file.url || file?.originFileObj?.url || '';
      if (!url) {
        return;
      }
      this.previewImageUrl = url;
      if (this.$refs.appImageViewerRef?.show) {
        this.$refs.appImageViewerRef.show();
      }
    },
    hidePreview() {
      this.previewImageUrl = '';
    },
  },
};
</script>

<style scoped lang="less">
.form-item {
  width: 50%;
  margin-right: 0;
}

.button-group {
  width: 100%;
}
</style>
