<template>
  <a-modal :visible="shown"
           :title="title"
           :confirm-loading="loading || uploading"
           :mask-closable="false"
           :destroy-on-close="true"
           :after-close="reset"
           width="600px"
           @ok="onSubmit"
           @cancel="close()">
    <a-form :form="form"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            class="form">
      <a-form-item label="环节名称（2级标题）">
        <a-input v-decorator="['title', decorator.title]"
                 :allow-clear="true"
                 name="title"
                 placeholder="请填写环节名称（2级标题）"/>
      </a-form-item>
      <a-form-item label="环节标签">
        <a-input v-decorator="['label', decorator.label]"
                 :allow-clear="true"
                 name="label"
                 :max-length="2"
                 placeholder="请填写环节标签（限 2 个字）"/>
      </a-form-item>

      <a-form-item label="选择类型">
        <a-select v-decorator="['type', decorator.type]"
                  :allow-clear="false"
                  name="type"
                  placeholder="请选择类型"
                  @change="typeChanged">
          <a-select-option v-for="(item) in typeList" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item v-show="type === 1" label="选择课程">
        <!--只查询下架课程-->
        <SelectCourse v-decorator="['course', decorator.course]"
                      :shelf-status="0"
                      mode="default"/>
      </a-form-item>
      <a-form-item v-show="type === 2" label="选择课包">
        <SelectCoursePackage v-decorator="['course_package', decorator.course_package]"
                             mode="default"/>
      </a-form-item>
      <!--      <a-form-item v-show="type === 3" label="选择课件">-->
      <!--        <SelectCourseTask v-decorator="['course_task', decorator.course_task]"-->
      <!--                          course-task-type="testpaper"-->
      <!--                          mode="default"/>-->
      <!--      </a-form-item>-->
      <a-form-item v-show="type === 6" label="上传图片">
        <a-upload v-decorator="['pic', decorator.pic]"
                  :multiple="false"
                  accept=".jpg, .jpeg, .png"
                  list-type="picture-card"
                  :disabled="uploading"
                  :show-upload-list="false"
                  :custom-request="(evt) => customRequest('pic', evt)"
                  :beforeUpload="(evt) => beforeUpload('pic', evt)">
          <img v-if="pic" :src="pic" alt="图片" class="cover-img"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item v-show="type === 8" label="上传视频">
        <a-upload v-decorator="['video', decorator.video]"
                  :multiple="false"
                  accept=".mp4"
                  list-type="picture-card"
                  class="relative-upload"
                  :remove="removeVideo"
                  @preview="previewVideo"
                  :disabled="uploading"
                  :show-upload-list="false"
                  :custom-request="(evt) => customRequest('video', evt)"
                  :beforeUpload="(evt) => beforeUpload('video', evt)">
          <div v-if="video || uploading" class="cover-wrapper" @click.prevent.stop="">
            <template v-if="uploading">
              <a-icon type="loading"/>
              <div class="ant-upload-text">上传中</div>
            </template>
            <template v-else>
              <video :src="video" class="cover-video"/>

              <div class="action-wrapper">
                <div class="preview" @click="handlePreviewVideo">预览</div>
                <div class="remove" @click="handleRemoveVideo">删除</div>
              </div>
            </template>
          </div>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item v-show="type === 8" label="上传视频封面">
        <a-upload v-decorator="['video_pic', decorator.video_pic]"
                  :multiple="false"
                  accept=".jpg, .jpeg, .png"
                  list-type="picture-card"
                  :disabled="uploading"
                  :show-upload-list="false"
                  :custom-request="(evt) => customRequest('video_pic', evt)"
                  :beforeUpload="(evt) => beforeUpload('video_pic', evt)">
          <img v-if="video_pic" :src="video_pic" alt="图片" class="cover-img"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item v-show="type === 7" label="地址">
        <a-input v-decorator="['url', decorator.url]"
                 :allow-clear="true"
                 name="url"
                 placeholder="请填写 H5 链接地址"/>
      </a-form-item>

      <a-form-item label="状态">
        <a-radio-group v-decorator="['status', decorator.status]" name="status">
          <a-radio v-for="(item) in statusList" :key="item.id" :value="item.id">{{ item.name }}</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="优先级">
        <a-input-number v-decorator="['sort', decorator.sort]"
                        :min="0"
                        :formatter="value => `${value}`.replace(/\D*/g, '')"
                        :parser="value => `${value}`.replace(/\D*/g, '')"
                        name="title"
                        placeholder="请填写优先级"
                        style="width: 180px;"/>
      </a-form-item>

    </a-form>
  </a-modal>
</template>

<script>
import {
  uploadFileToPublic,
} from '@/api/Common';
import {
  getSegmentDetail,
  updateSegment,
} from '../api/config';

import SelectCourse from '../../../../components/select/SelectCourse.vue';
import SelectCoursePackage from '../../../../components/select/SelectCoursePackage.vue';
// import SelectCourseTask from '../../../../components/select/SelectCourseTask.vue';

const decorator = {
  title: {
    rules: [{ required: true, message: '请填写环节名称（2级标题）' }],
  },
  label: {
    rules: [{ required: true, message: '请填写环节标签' }],
  },
  type: {
    rules: [{ required: true, message: '请选择类型' }],
  },
  course: {
    rules: [{ required: true, message: '请选择课程' }],
  },
  course_package: {
    rules: [{ required: false, message: '请选择课包' }],
  },
  // course_task: {
  //   rules: [{ required: false, message: '请选择课件' }],
  // },
  pic: {
    valuePropName: 'fileList',
    getValueFromEvent: (e) => Array.isArray(e) ? e : (e && e.fileList),
    rules: [{ required: false, message: '请上传图片' }],
  },
  video: {
    valuePropName: 'fileList',
    getValueFromEvent: (e) => Array.isArray(e) ? e : (e && e.fileList),
    rules: [{ required: false, message: '请上传视频' }],
  },
  video_pic: {
    valuePropName: 'fileList',
    getValueFromEvent: (e) => Array.isArray(e) ? e : (e && e.fileList),
    rules: [{ required: false, message: '请上传视频封面' }],
  },
  url: {
    rules: [{ required: false, message: '请填写 H5 链接地址' }],
  },
  status: {
    rules: [{ required: true, message: '请选择状态' }],
  },
  sort: {
    rules: [{ required: true, message: '请填写优先级' }],
  },
};

export default {
  name: 'editSegmentModal',
  components: {
    SelectCourse,
    SelectCoursePackage,
    // SelectCourseTask,
  },
  inject: ['activityId'],
  computed: {
    title() {
      return this.id ? '修改环节' : '添加环节';
    },
  },
  data() {
    return {
      loading: false,
      uploading: false, // 是否正在上传图片
      shown: false,

      typeList: [
        { id: 1, name: '课程', field: 'course' },
        { id: 2, name: '课程包', field: 'course_package' },
        // { id: 3, name: '考试', field: 'course_task' },
        { id: 3, name: '考试' }, // 考试类型不需要选择课件
        { id: 4, name: 'H5 - 作品上传' },
        { id: 5, name: 'H5 - 荣耀展台' },
        { id: 6, name: '长图', field: 'pic' },
        { id: 7, name: 'H5链接', field: 'url' },
        { id: 8, name: '视频', field: 'video' },
      ],
      statusList: [
        { id: 0, name: '关闭' },
        { id: 1, name: '开启' },
      ],
      decorator,

      id: '',
      stageId: '',

      original: {},

      type: 1,
      pic: '',
      video: '',
      video_pic: '',
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {});
  },
  methods: {
    show({ id, stageId } = {}) {
      this.id = id;
      this.stageId = stageId;
      this.getSegmentDetail();

      this.shown = true;

      if (!id) {
        this.$nextTick(() => this.setDecorator({
          type: 1,
          status: 0,
          sort: 1,
        }));
      }
    },
    close() {
      this.shown = false;
    },
    reset() {
      this.id = '';
      this.stageId = '';
      this.pic = '';
      this.video = '';
      this.video_pic = '';
    },

    async getSegmentDetail() {
      if (!this.id) {
        return;
      }

      this.loading = true;
      const data = await getSegmentDetail({
        id: this.id,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取阶段详情失败');
        return;
      }
      this.original = { ...(data?.data || {}) };
      this.setDecorator(data?.data || {});
    },

    onSubmit() {
      this.form.validateFields((error, values) => {
        if (!error) {
          const params = { ...values };

          if (params.type === 6) {
            const pic = [...(params.pic || [])].reverse()[0] || {};
            params.pic = pic.file_resource_id || pic.originFileObj?.file_resource_id || '';
          }
          if (params.type === 8) {
            const video = [...(params.video || [])].reverse()[0] || {};
            params.video = video.file_resource_id || video.originFileObj?.file_resource_id || '';

            const videoPic = [...(params.video_pic || [])].reverse()[0] || {};
            params.video_image_id = videoPic.file_resource_id || videoPic.originFileObj?.file_resource_id || '';
          }

          const field = this.typeList.filter((i) => i.id === params.type)?.[0]?.field;
          if (field) {
            params.type_value = params[field];
          } else {
            params.type_value = '';
          }
          if (this.id) {
            params.id = this.id;
          }
          params.activity_id = this.activityId;
          params.activity_module_id = this.stageId;

          delete params[field];
          delete params.video_pic;

          this.save(params);
        }
      });
    },
    async save(params) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await updateSegment(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');

      const evt = {
        ...params,
        pic: this.pic,
        status_name: this.statusList.filter((i) => i.id === params.status)?.[0]?.name || '',
      };
      if (this.original?.sort !== evt.sort) {
        evt.id = undefined;
      }
      this.$emit('saved', evt);
      this.close();
    },

    setDecorator(data = {}) {
      const values = {};
      Object.keys(this.decorator).forEach((key) => {
        if (data[key] !== undefined && data[key] !== null) {
          values[key] = data[key];
        }
      });

      const field = this.typeList.filter((i) => i.id === +data.type)?.[0]?.field;
      if (field === 'pic') {
        this.pic = data.type_value_data.url || '';
        values.pic = data.type_value ? [{
          uid: `${data.type_value}`,
          name: `${data.type_value_data.url.split('/').reverse()[0]}`,
          status: 'done',
          url: data.type_value_data.url,
          file_resource_id: data.type_value,
        }] : [];
      } else if (field === 'video') {
        this.video = data.type_value_data?.url || '';
        values.video = data.type_value ? [{
          uid: `${data.type_value}`,
          name: `${data.type_value_data.url.split('/').reverse()[0]}`,
          status: 'done',
          url: data.type_value_data.url,
          file_resource_id: data.type_value,
        }] : [];
      } else if (data.type_value !== undefined && data.type_value !== null) {
        values[field] = data.type_value;
      }
      if (data.video_image_id) {
        this.video_pic = data.video_image || '';
        values.video_pic = data.video_image ? [{
          uid: `${data.video_image_id}`,
          name: `${data.video_image.split('/').reverse()[0]}`,
          status: 'done',
          url: data.video_image,
          file_resource_id: data.video_image_id,
        }] : [];
      }

      if (data.type) {
        this.type = data.type;
      }
      this.updateDecorator([field]);

      this.form.setFieldsValue(values);
    },

    updateDecorator(requiredField) {
      this.typeList.map((i) => i.field).filter((i) => !!i).forEach((i) => {
        this.decorator[i].rules.forEach((r) => {
          r.required = false;
        });
      });
      requiredField.forEach((field) => {
        if (this.decorator[field]?.rules?.length) {
          this.decorator[field].rules.forEach((r) => {
            if (field !== 'video' && field !== 'video_pic') { // 视频为未必传项
              r.required = true;
            }
          });
        }
      });
      this.decorator = { ...this.decorator };
    },

    typeChanged(val) {
      this.type = val;

      const field = this.typeList.filter((i) => i.id === val).map((i) => i.field)?.[0];

      this.updateDecorator(field ? [field] : []);
    },

    beforeUpload(field, file) {
      const imgFields = ['pic', 'video_pic'];

      const types = imgFields.includes(field) ? ['jpg', 'jpeg', 'png'] : ['mp4'];
      const maxSize = imgFields.includes(field) ? 0 : 300;
      const typeString = imgFields.includes(field) ? '图片' : '视频';

      const type = file.type;
      const size = file.size;

      return new Promise((resolve, reject) => {
        if (types.every((t) => !type.includes(t))) {
          this.$message.info(`${typeString}仅支持 ${
            types.map((i) => '.' + i).join(' ')
          } 格式，请重新选择${typeString}`);
          reject('ImageTypeError');
        } else if (maxSize && size > maxSize * 1024 * 1024) {
          this.$message.info(`${typeString}大小不能超过 ${maxSize}M，请重新选择${typeString}`);
          reject('ImageSizeError');
        } else {
          resolve();
        }
      });
    },
    async customRequest(field, { file, onSuccess, onError }) {
      const imgFields = ['pic', 'video_pic'];

      const typeString = imgFields.includes(field) ? '图片' : '视频';

      if (this.uploading) {
        onError('Uploading');
        return;
      }
      this.uploading = true;

      const params = new FormData();
      params.append('file', file);
      params.append('source', 'common');

      const data = await uploadFileToPublic(params);
      if (!data || data.error_code) {
        this.video = '';
        onError('Uploading');
        this.$message.error(data?.message || `${typeString}上传失败`);
        return;
      }
      this.$message.success(data.message || `${typeString}上传成功`);

      this.uploading = false;
      file.url = data.data.url;
      file.file_resource_id = data.data.file_resource_id;
      this[field] = file.url;

      onSuccess(data, file);

      setTimeout(() => {
        const decoratorData = {};
        if (field === 'pic') {
          decoratorData.type = 6;
          decoratorData.type_value = data.data.file_resource_id;
          decoratorData.type_value_data = {
            url: data.data.url,
          };
        }
        if (field === 'video') {
          decoratorData.type = 8;
          decoratorData.type_value = data.data.file_resource_id;
          decoratorData.type_value_data = {
            url: data.data.url,
          };
        }
        if (field === 'video_pic') {
          decoratorData.video_image = data.data.url;
          decoratorData.video_image_id = data.data.file_resource_id;
        }
        this.setDecorator(decoratorData);
      }, 0);
    },

    previewVideo(file) {
      window.open(file.url || file.originFileObj?.url, '_blank');
    },
    removeVideo() {
      this.video = '';
      this.setDecorator({ type: 8 });
    },

    handlePreviewVideo() {
      const [file] = this.form.getFieldValue('video') || [];
      if (!file) {
        this.$message.error('未找到预览地址');
        return;
      }
      window.open(file.url || file.originFileObj?.url, '_blank');
    },
    handleRemoveVideo() {
      const [file] = this.form.getFieldValue('video') || [];
      if (!file) {
        this.$message.error('未找到视频地址');
        return;
      }
      this.setDecorator({ type: 8 });
    },
  },
};
</script>

<style scoped lang="less">
.form {
  padding-bottom: 90px;
}

.relative-upload ::v-deep .ant-upload {
  position: relative;
}

.cover-img {
  max-width: 86px;
  max-height: 86px;
}

.cover-wrapper {
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;

  .action-wrapper {
    display: none;
  }

  &:hover .action-wrapper {
    display: flex;
  }
}

.cover-video {
  max-width: 100%;
  max-height: 100%;
}

.action-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #EFEFEF;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: default;

  .preview {
    margin-right: 10px;
    padding: 6px 2px;
    cursor: pointer;
  }

  .remove {
    padding: 6px 2px;
    cursor: pointer;
  }
}
</style>
