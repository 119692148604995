<template>
  <a-modal :visible="shown"
           :title="title"
           :confirm-loading="loading"
           :mask-closable="false"
           :destroy-on-close="true"
           :after-close="reset"
           width="600px"
           @ok="onSubmit"
           @cancel="close()">
    <a-form :form="form"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }">
      <a-form-item label="岗位">
        <app-select-job v-decorator="['post_id', decorator.post_id]"
                        name="post_id"
                        mode="default"
                        class="select-job"
                        placeholder="请选择岗位"
                        @change="jobChanged"/>
      </a-form-item>

      <a-form-item label="入口名称1级标题">
        <a-input v-decorator="['primary_title', decorator.primary_title]"
                 :allow-clear="true"
                 name="primary_title"
                 placeholder="请填写入口名称1级标题"/>
      </a-form-item>
      <a-form-item label="2级标题">
        <a-input v-decorator="['secondary_title', decorator.secondary_title]"
                 :allow-clear="true"
                 name="secondary_title"
                 placeholder="请填写2级标题"/>
      </a-form-item>

      <a-form-item label="上传文件类型">
        <a-select v-decorator="['file_type', decorator.file_type]"
                  :allow-clear="false"
                  name="file_type"
                  placeholder="请选择上传文件类型">
          <a-select-option v-for="(item) in fileTypeList" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="状态">
        <a-radio-group v-decorator="['status', decorator.status]" name="status">
          <a-radio v-for="(item) in statusList" :key="item.id" :value="item.id">{{ item.name }}</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="截止时间">
        <a-date-picker v-decorator="['end_time', decorator.end_time]"
                       :show-time="{ format: 'HH:mm' }"
                       name="end_time"/>
      </a-form-item>

      <a-form-item label="优先级">
        <a-input-number v-decorator="['sort', decorator.sort]"
                        :min="0"
                        :formatter="value => `${value}`.replace(/\D*/g, '')"
                        :parser="value => `${value}`.replace(/\D*/g, '')"
                        name="title"
                        placeholder="请填写优先级"
                        style="width: 180px;"/>
      </a-form-item>

    </a-form>
  </a-modal>
</template>

<script>
import moment from 'moment';
import {
  getOpusDetail,
  updateOpus,
} from '../api/config';

const decorator = {
  post_id: {
    rules: [{ required: true, message: '请选择岗位' }],
  },
  primary_title: {
    rules: [{ required: true, message: '请填写入口名称1级标题' }],
  },
  secondary_title: {
    rules: [{ required: false, message: '请填写2级标题' }],
  },
  file_type: {
    rules: [{ required: true, message: '请选择上传文件类型' }],
  },
  status: {
    rules: [{ required: true, message: '请选择状态' }],
  },
  end_time: {
    rules: [{ required: true, message: '请选择截止时间' }],
  },
  sort: {
    rules: [{ required: true, message: '请填写优先级' }],
  },
};

export default {
  name: 'editOpusUploadModal',
  inject: ['activityId'],
  computed: {
    title() {
      return this.id ? '修改作品上传' : '添加作品上传';
    },
  },
  data() {
    return {
      loading: false,
      shown: false,

      fileTypeList: [
        { id: 1, name: 'doc文档' },
        { id: 2, name: 'xls表格' },
        { id: 3, name: 'pdf' },
        { id: 4, name: 'ppt' },
        { id: 5, name: 'picture图片' },
        { id: 6, name: 'video视频' },
        { id: 7, name: 'voice音频' },
        { id: 8, name: 'other其他' },
      ],
      statusList: [
        { id: 0, name: '关闭' },
        { id: 1, name: '开启' },
      ],
      decorator,

      id: '',
      stageId: '',
      record: {},

      original: {},

      jobName: '',
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {});
  },
  methods: {
    show({ id, stageId, record } = {}) {
      this.id = id;
      this.stageId = stageId;
      this.record = record;
      this.getOpusUploadDetail();

      this.shown = true;

      if (!id) {
        this.$nextTick(() => this.setDecorator({
          status: 0,
          sort: 1,
        }));
      }
    },
    close() {
      this.shown = false;
    },
    reset() {
      this.id = '';
      this.stageId = '';
    },

    async getOpusUploadDetail() {
      if (!this.id) {
        return;
      }

      this.loading = true;
      const data = await getOpusDetail({
        id: this.id,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取阶段详情失败');
        return;
      }
      this.original = { ...(data?.data || {}) };
      this.setDecorator(data?.data || {});
    },

    onSubmit() {
      this.form.validateFields((error, values) => {
        if (!error) {
          const params = {
            ...values,
            end_time: values.end_time ? Math.floor(
              values.end_time.toDate().getTime() / (1000 * 60),
            ) * 60 : '',
          };

          if (this.id) {
            params.id = this.id;
          }
          params.activity_id = this.activityId;
          params.activity_module_id = this.stageId;

          this.save(params);
        }
      });
    },
    async save(params) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await updateOpus(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');

      const evt = {
        ...params,
        file_type_name: this.fileTypeList.filter((i) => i.id === params.file_type)?.[0]?.name || '',
        status_name: this.statusList.filter((i) => i.id === params.status)?.[0]?.name || '',
      };
      if (this.jobName) {
        evt.post_name = this.jobName;
      }
      if (this.original?.sort !== evt.sort) {
        evt.id = undefined;
      }
      this.$emit('saved', evt);
      this.close();
    },

    setDecorator(data = {}) {
      const values = {};
      Object.keys(this.decorator).forEach((key) => {
        if (data[key] !== undefined && data[key] !== null) {
          values[key] = data[key];
        }
      });

      if (values.post_id) {
        values.post_id = values.post_id + '';
      }
      if (values.file_type) {
        values.file_type = +values.file_type;
      }
      if (values.end_time) {
        values.end_time = moment(values.end_time * 1000);
      }

      this.form.setFieldsValue(values);
    },

    jobChanged(ids, items) {
      this.jobName = items?.[0]?.name || '';

      const primaryTitle = this.record.children.filter(
        (i) => +i.post_id === +ids,
      )?.[0]?.primary_title || '';
      if (primaryTitle) {
        this.setDecorator({ primary_title: primaryTitle });
      }
    },
  },
};
</script>

<style scoped lang="less">
.select-job {
  transform: translateY(5px);
}
</style>
