<template>
  <div>
    <div class="flex flex-end ph-20 mb-10">
      <a-button type="primary" @click="addStage">添加阶段</a-button>
    </div>

    <app-loading-status :non-data="!(list && list.length)" :is-loading="loading"/>

    <div class="ph-20">
      <a-collapse v-if="list && list.length" v-model="activeKey">
        <a-collapse-panel v-for="item of list" :key="item.id.toString()" :header="null">
          <div slot="extra" class="flex space-between panel-extra">
            <div class="flex flex-start flex-wrap">
              <span class="flex-none mr-20">优先级：{{ item.sort }}</span>

              <span class="flex-none mr-20">导航名：{{ item.title }}</span>
              <div class="flex-none mr-5">
                导航图标：
                <app-image-viewer :url="item.logo_url"
                                  :hide-placeholder-img="true"
                                  @click.native.stop="">
                  <img :src="item.logo_url" alt="" class="mr-20 title_icon">
                </app-image-viewer>
              </div>

              <span class="flex-none mr-20">1级标题：{{ item.phase_title }}</span>
              <div class="flex-none mr-5">
                1级图标：
                <app-image-viewer :url="item.phase_logo_url"
                                  :hide-placeholder-img="true"
                                  @click.native.stop="">
                  <img :src="item.phase_logo_url" alt="" class="mr-20 title_icon">
                </app-image-viewer>
              </div>

              <span class="flex-none mr-20">当前状态：{{ item.status_name }}</span>
            </div>

            <div class="flex flex-none" @click.stop="">
              <a-button type="link" @click="addSegment(item)">添加环节</a-button>
              <a-button type="link" @click="editStage(item)">编辑</a-button>
              <a-button type="link" @click="deleteStage(item)">删除</a-button>
            </div>
          </div>

          <template v-if="item.children && item.children.length">
            <a-table :columns="columns"
                     :data-source="item.children"
                     :pagination="false"
                     :loading="loading"
                     row-key="id"
                     :scroll="{ x: '100%' }"
                     class="mb-10 table small-cell-table">
              <template slot="action" slot-scope="text, record">
                <a-button type="link" @click="editSegment(record, item)">编辑</a-button>
                <a-button type="link" @click="changeSegmentStatus(record)">
                  {{ +record.status === 1 ? '关闭' : '开启' }}
                </a-button>
                <a-button type="link" @click="deleteSegment(record)">删除</a-button>
              </template>
            </a-table>
          </template>
          <template v-else>
            <div class="row row-center">暂无数据</div>
          </template>
        </a-collapse-panel>
      </a-collapse>
    </div>

    <EditStageModal ref="editStageModalRef"
                    @saved="updateStageForce"/>
    <EditSegmentModal ref="editSegmentModalRef"
                      @saved="updateSegmentForce"/>
  </div>
</template>

<script>
import {
  getStageList,
  deleteStage,
  deleteSegment, updateSegmentStatus,
} from '../api/config';

import EditStageModal from '../components/editStageModal.vue';
import EditSegmentModal from '../components/editSegmentModal.vue';

const columns = [
  {
    width: '180px',
    align: 'center',
    dataIndex: 'title',
    key: 'title',
    title: '环节名称',
  },
  {
    width: '90px',
    align: 'center',
    dataIndex: 'label',
    key: 'label',
    title: '环节标签',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'type_name',
    key: 'type_name',
    title: '类型',
  },
  {
    width: '180px',
    align: 'center',
    dataIndex: 'jump_targets',
    key: 'jump_targets',
    title: '跳转目标',
    customRender: (targets) => targets?.length ? targets.join(', ') : '--',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'staus_name',
    key: 'staus_name',
    title: '当前状态',
  },
  {
    width: '60px',
    align: 'center',
    dataIndex: 'sort',
    key: 'sort',
    title: '优先级',
  },
  {
    width: '160px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    fixed: 'right',
  },
];

export default {
  name: 'stage',
  components: {
    EditStageModal,
    EditSegmentModal,
  },
  inject: ['activityId'],
  data() {
    return {
      loading: false,

      activeKey: [],

      columns,
      list: [],
    };
  },
  created() {
    this.getStageList();
  },
  methods: {
    async getStageList() {
      this.loading = true;
      const data = await getStageList({
        activity_id: this.activityId,
      }).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取数据失败');
        return;
      }
      this.list = data?.data || [];
      this.activeKey = this.list.map((i) => i.id);
    },

    addStage() {
      this.showEditStageModal();
    },
    editStage(item) {
      this.showEditStageModal(item.id);
    },
    showEditStageModal(id) {
      if (this.$refs.editStageModalRef?.show) {
        this.$refs.editStageModalRef.show({ id });
      }
    },
    updateStageForce() {
      this.getStageList();
    },
    updateStage(params) {
      const [item] = this.list.filter((i) => i.id === params.id);

      if (!item?.id || params.sort !== item.sort) {
        this.getStageList();
        return;
      }

      Object.keys(params).forEach((key) => {
        item[key] = params[key];
      });
    },

    deleteStage(record) {
      this.$confirm({
        title: '提示',
        content: `确认删除阶段 "${record.title}" ？`,
        onOk: () => this.confirmDeleteStage(record),
      });
    },
    async confirmDeleteStage(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await deleteStage({ module_id: record.id }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '删除失败');
        return;
      }
      this.$message.success(data?.message || '删除成功');
      this.list = this.list.filter((i) => i.id !== record.id);
    },

    addSegment(record) {
      this.showEditSegmentModal(undefined, record.id);
    },
    editSegment(item, record) {
      this.showEditSegmentModal(item.id, record.id);
    },
    showEditSegmentModal(id, stageId) {
      if (this.$refs.editSegmentModalRef?.show) {
        this.$refs.editSegmentModalRef.show({
          id,
          stageId,
        });
      }
    },
    updateSegmentForce() {
      this.getStageList();
    },
    updateSegment(params) {
      const [item] = this.list.flatMap(
        (i) => i.children?.length ? i.children : [],
      ).filter(
        (i) => i.id === params.id,
      ) || [];

      if (!item?.id) {
        this.getStageList();
        return;
      }

      Object.keys(params).forEach((key) => {
        item[key] = params[key];
      });
    },

    async changeSegmentStatus(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await updateSegmentStatus({
        link_id: record.id,
        status: (record.status + 1) % 2,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '状态修改失败');
        return;
      }
      this.$message.success(data?.message || '状态修改成功');
      this.list.forEach((item) => {
        if (item.children?.length) {
          item.children.forEach((i) => {
            if (i.id === record.id) {
              i.status = (record.status + 1) % 2;
              i.staus_name = i.status ? '开启' : '关闭';
            }
          });
        }
      });
    },

    deleteSegment(record) {
      this.$confirm({
        title: '提示',
        content: `确认删除环节 "${record.title}" ？`,
        onOk: () => this.confirmDeleteSegment(record),
      });
    },
    async confirmDeleteSegment(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await deleteSegment({ id: record.id }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '删除失败');
        return;
      }
      this.$message.success(data?.message || '删除成功');
      this.list.forEach((item) => {
        if (item.children?.length) {
          const index = item.children.findIndex((i) => i.id === record.id);
          if (index > -1) {
            item.children.splice(index, 1);
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
::v-deep .ant-collapse-extra {
  float: none !important;
}

.title_icon {
  max-width: 40px;
  max-height: 40px;
}
</style>
