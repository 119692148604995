<template>
  <router-view/>
</template>

<script>
// import Vue from 'vue';
// import { getActivityInfo } from './api';

let activityId = 1;

export default {
  name: 'index',
  // beforeRouteEnter(to, from, next) {
  //   getActivityInfo().then((data) => {
  //     const id = data?.data?.activity_id || '';
  //
  //     if (id) {
  //       activityId = id;
  //       next();
  //     } else {
  //       Vue.prototype.$message.error('获取活动 id 失败，请重试');
  //     }
  //   }).catch(() => {
  //     Vue.prototype.$message.error('获取活动 id 失败，请重试');
  //   });
  // },
  provide() {
    return {
      activityId,
    };
  },
};
</script>

<style scoped lang="less">

</style>
