<template>
  <div>
    <div class="flex flex-start flex-wrap ph-20 mb-10">
      <div class="flex mr-30 mb-10">
        <span class="flex-none">对应阶段：</span>
        <SelectStage v-model="form.stageIds" style="min-width: 220px;"/>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">标题：</span>
        <SelectTitle v-model="form.titleId" style="min-width: 220px;"/>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">提报作品类型：</span>
        <SelectFileType v-model="form.file_type" :allow-clear="true" style="min-width: 220px;"/>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">选择上传时间段：</span>
        <a-range-picker v-model="form.dateRage"/>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">组织机构：</span>
        <app-select-org v-model="form.org_ids" :show-select-all-org="true"/>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">店型：</span>
        <SelectShopType v-model="form.shopType"/>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">岗位：</span>
        <app-select-job v-model="form.post_ids"/>
      </div>

      <div class="flex mr-30 mb-10">
        <a-button type="primary" @click="onSearch()">查询</a-button>
        <a-button @click="onReset(true)">重置</a-button>
        <a-button type="primary" @click="onExportExcel(true)">导出提报记录</a-button>
      </div>
    </div>

    <div class="ph-20">
      <a-table ref="tableRef"
               :columns="columns"
               :data-source="data"
               :pagination="false"
               :row-selection="rowSelection"
               :loading="fetching"
               :scroll="{ x: '100%' }"
               row-key="id"
               class="mb-10 table small-cell-table">
        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="previewRecord(record)">预览</a-button>
          <a-button type="link" @click="gradeRecord(record)">打分</a-button>
          <a-button type="link" @click="downloadRecord(record)">下载</a-button>
          <a-button type="link" @click="toGradeDetail(record)">查看打分明细</a-button>
        </template>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"/>
        </template>
      </a-table>
    </div>

    <GradeDetailModal ref="gradeDetailModalRef"/>
    <GradeModal ref="gradeModalRef" @saved="getData(
      pagination.current,
      pagination.pageSize,
    )"/>
  </div>
</template>

<script>
import moment from 'moment';

import table from '../../../../mixins/table';
import { getWorksList, getWorksListExport } from '../api/config';

import SelectShopType from '../../../../components/select/SelectShopType.vue';

import SelectStage from '../components/selectStage.vue';
import SelectTitle from '../components/selectTitle.vue';
import SelectFileType from '../components/selectFileType.vue';

import GradeDetailModal from '../components/gradeDetailModal.vue';
import GradeModal from '../components/gradeModal.vue';

const defaultForm = {
  stageIds: '',
  titleId: [],
  file_type: undefined,
  dateRage: [undefined, undefined],
  post_ids: '',
  shopType: { store_state: '1' },
  org_ids: '',
};

const columns = [
  {
    width: '180px',
    align: 'center',
    dataIndex: 'big_area_name',
    key: 'big_area_name',
    title: '大区',
  },
  {
    width: '280px',
    align: 'center',
    dataIndex: 'org_name',
    key: 'org_name',
    title: '经销商',
  },
  {
    width: '180px',
    align: 'center',
    dataIndex: 'org_shortname',
    key: 'org_shortname',
    title: '经销商简称',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'truename',
    key: 'truename',
    title: '姓名',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'post_name',
    key: 'post_name',
    title: '岗位',
  },
  {
    width: '140px',
    align: 'center',
    dataIndex: 'mobile',
    key: 'mobile',
    title: '手机号',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'updated_at',
    key: 'updated_at',
    title: '上传时间',
    customRender: (text) => text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : '--',
  },
  {
    width: '80px',
    align: 'center',
    dataIndex: 'type_name',
    key: 'type_name',
    title: '类型',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'module_name',
    key: 'module_name',
    title: '对应阶段',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'primary_title',
    key: 'primary_title',
    title: '1级标题',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'secondary_title',
    key: 'secondary_title',
    title: '2级标题',
  },
  {
    width: '80px',
    align: 'center',
    dataIndex: 'user_score',
    key: 'user_score',
    title: '您的打分',
  },
  {
    width: '80px',
    align: 'center',
    dataIndex: 'average',
    key: 'average',
    title: '平均分',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'score_user_list',
    key: 'score_user_list',
    title: '参与打分',
    customRender: (list) => (list || []).join(', '),
  },
  {
    width: '280px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    fixed: 'right',
  },
];

export default {
  name: 'grade',
  components: {
    SelectShopType,
    SelectStage,
    SelectTitle,
    SelectFileType,
    GradeDetailModal,
    GradeModal,
  },
  mixins: [table],
  inject: ['activityId'],
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.selectedRows = selectedRows;
        },
      };
    },
  },
  data() {
    return {
      defaultForm: { ...defaultForm },
      form: { ...defaultForm },

      originColumns: columns,

      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    getParams(page, pageSize) {
      const format = 'YYYY-MM-DD';

      return {
        page,
        page_size: pageSize,
        activity_id: this.activityId,
        module_id: this.form.stageIds || '',
        title_id: [...(this.form.titleId || [])].reverse()?.[0] || '',
        file_type: this.form.file_type || '',
        start_time: this.form.dateRage?.[0]?.format ? this.form.dateRage[0].format(format) : '',
        end_time: this.form.dateRage?.[1]?.format ? this.form.dateRage[1].format(format) : '',
        org_ids: this.form.org_ids || '',
        post_ids: this.form.post_ids || '',

        is_open: this.form.shopType?.store_state || '',
        org_type: this.form.shopType?.store_type || '',
        store_state: this.form.shopType?.store_state || '',
        store_type: this.form.shopType?.store_type || '',
        operation_state: this.form.shopType?.operation_state || '',
        store_age: this.form.shopType?.store_age || '',
      };
    },

    getData(page, pageSize) {
      this.executeGetData(async () => {
        const params = this.getParams(page, pageSize);

        const data = await getWorksList(params);
        if (!data || data.error_code) {
          this.$message.error(data?.message || '获取数据失败');
          return Promise.reject(data);
        }
        this.data = (data.data || []).map((i, index) => ({
          ...i,
          rowIndex: (page - 1) * 10 + (index + 1),
        }));
        return Promise.resolve(data);
      });
    },

    onExportExcel() {
      if (!this.selectedRowKeys?.length) {
        this.$message.info('请选择要导出的数据');
        return;
      }
      this.executeExportExcel(async () => {
        const params = this.getParams(this.pagination.current, this.pagination.pageSize);
        params.work_ids = (this.selectedRowKeys || []).join(',');
        params.down = 1;

        const data = await getWorksListExport(params);

        if (!data || data?.error_code) {
          return Promise.reject(data);
        }
        this.selectedRowKeys = [];
        this.selectedRows = [];
        return Promise.resolve(data);
      });
    },

    // 打分
    gradeRecord(record) {
      if (this.$refs.gradeModalRef?.show) {
        this.$refs.gradeModalRef.show({
          id: record.id,
          score: record.user_score,
        });
      }
    },

    // 查看打分明细
    toGradeDetail(record) {
      if (this.$refs.gradeDetailModalRef?.show) {
        this.$refs.gradeDetailModalRef.show({ id: record.id });
      }
    },

    // 预览文件
    previewRecord(record) {
      if (!record.file_url) {
        this.$message.info('未找到文件地址');
        return;
      }
      window.open(record.file_url, '_blank');
    },

    // 下载单个文件
    downloadRecord(record) {
      if (!record.download_url) {
        this.$message.info('未找到文件地址');
        return;
      }

      window.location.href = record.download_url;
    },
  },
};
</script>

<style scoped lang="less">

</style>
