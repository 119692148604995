<template>
  <a-modal :visible="shown"
           :title="title"
           :confirm-loading="loading"
           :mask-closable="true"
           :destroy-on-close="true"
           :after-close="reset"
           :footer="null"
           width="400px"
           @ok="close()"
           @cancel="close()">
    <app-loading-status :non-data="!(list && list.length)" :is-loading="loading"/>

    <template v-if="list && list.length">
      <div class="content">
        <div v-for="(item) in list" :key="item.id" class="flex flex-start">
          <div class="user">{{ item.truename }}</div>
          <div class="score">{{ item.score }}</div>
        </div>
      </div>

      <div class="flex flex-start average pt-10 mt-10">
        <div class="user">平均分</div>
        <div class="score">{{ average }}</div>
      </div>
    </template>

    <div class="flex pt-20">
      <a-button type="primary" @click="close()">关闭</a-button>
    </div>
  </a-modal>
</template>

<script>
import { getGradeRecord } from '../api/config';

export default {
  name: 'gradeDetailModal',
  inject: ['activityId'],
  computed: {
    title() {
      return '打分明细';
    },
  },
  data() {
    return {
      loading: false,
      shown: false,

      id: '',

      list: [],
      average: 0,
    };
  },
  methods: {
    show({ id } = {}) {
      this.id = id;
      this.getGradeRecord();

      this.shown = true;
    },
    close() {
      this.shown = false;
    },
    reset() {
      this.id = '';
      this.average = 0;
      this.list = [];
    },

    async getGradeRecord() {
      if (!this.id) {
        return;
      }

      this.loading = true;
      const data = await getGradeRecord({ id: this.id }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取失败');
        return;
      }

      this.list = data?.data || [];
      this.average = data?.meta?.average || 0;
    },
  },
};
</script>

<style scoped lang="less">
.content {
  max-height: 70vh;
}

.user {
  width: 50%;
}

.average {
  border-top: 1px solid #DFDFDF;
}
</style>
