import { stringify } from 'qs';
import { baseUrl } from '@/Config';
import store from '../../../../store';

import request from '../../../../utils/Request';

import download from '../../../../utils/download/download';


// 统计-最高成绩
export function exportMaxScore(params) {
  let url = '/activity/admin/statistical/highest';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return download({
    url: path,
    token: store.state.User.token,
  });
}

// 统计-全部成绩
export function exportAllScore(params) {
  let url = '/activity/admin/statistical/score';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return download({
    url: path,
    token: store.state.User.token,
  });
}

// 统计-全部题目
export function exportPersonalQuiz(params) {
  let url = '/activity/admin/statistical/all';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return download({
    url: path,
    token: store.state.User.token,
  });
}

// 统计-全部题目 - 返回文件地址
export function getPersonalQuizDownloadUrl(params) {
  return request({
    url: '/activity/admin/statistical/all',
    method: 'get',
    params,
  });
}

// 统计-全部题目 TEST 测试用，可以删除，注意删除引用
export function exportPersonalQuizTEST(params) {
  let url = '/actdown';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return download({
    url: path,
    token: store.state.User.token,
  });
}

// 统计-试题分析
export function exportQuizAnalysis(params) {
  let url = '/activity/admin/statistical/analyse';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return download({
    url: path,
    token: store.state.User.token,
  });
}

// 课程学习明细
export function exportCourseLearnProcess(params) {
  let url = '/participation/admin/studytime';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return download({
    url: path,
    token: store.state.User.token,
  });
}

// 课件学习明细
export function exportCourseTaskLearnProcess(params) {
  let url = '/participation/admin/studytime/task';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return download({
    url: path,
    token: store.state.User.token,
  });
}
