<template>
  <a-modal :visible="shown"
           :title="title"
           :confirm-loading="loading || uploading"
           :mask-closable="false"
           :destroy-on-close="true"
           :after-close="reset"
           width="600px"
           @ok="onSubmit"
           @cancel="close()">
    <a-form :form="form"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }">
      <a-form-item label="榜单名称">
        <a-input v-decorator="['title', decorator.title]"
                 :allow-clear="true"
                 name="title"
                 placeholder="请填写榜单名称"/>
      </a-form-item>

      <a-form-item label="上传头图">
        <a-upload v-decorator="['head_pic_id', decorator.head_pic_id]"
                  name="head_pic_id"
                  :multiple="false"
                  accept=".jpg, .jpeg, .png"
                  list-type="picture-card"
                  :disabled="uploading"
                  :show-upload-list="false"
                  :custom-request="(evt) => customRequest('head_pic_id', evt)"
                  :beforeUpload="beforeUpload">
          <img v-if="head_pic_id" :src="head_pic_id" alt="图片" class="cover-img"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-item>

      <a-form-item label="展示人数">
        <a-input-number v-decorator="['top_user_num', decorator.top_user_num]"
                        :mi="1"
                        :formatter="value => `${value}`.replace(/\D*/g, '')"
                        :parser="value => `${value}`.replace(/\D*/g, '')"
                        name="top_user_num"
                        placeholder="请填写排行榜展示人数"
                        style="width: 180px;"/>
      </a-form-item>

      <a-form-item label="状态">
        <a-radio-group v-decorator="['is_open', decorator.is_open]" name="is_open">
          <a-radio v-for="(item) in statusList" :key="item.id" :value="item.id">{{ item.name }}</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="优先级">
        <a-input-number v-decorator="['sort_num', decorator.sort_num]"
                        :min="0"
                        :formatter="value => `${value}`.replace(/\D*/g, '')"
                        :parser="value => `${value}`.replace(/\D*/g, '')"
                        name="sort_num"
                        placeholder="请填写优先级"
                        style="width: 180px;"/>
      </a-form-item>

    </a-form>
  </a-modal>
</template>

<script>
import {
  uploadFileToPublic,
} from '@/api/Common';
import {
  getShowcasePostBillboardDetail,
  updateShowcasePostBillboard,
} from '../api/config';

const decorator = {
  title: {
    rules: [{ required: true, message: '请填写榜单名称' }],
  },
  head_pic_id: {
    valuePropName: 'fileList',
    getValueFromEvent: (e) => Array.isArray(e) ? e : (e && e.fileList),
    rules: [{ required: true, message: '请上传头图' }],
  },
  top_user_num: {
    rules: [{ required: true, message: '请填写排行榜展示人数' }],
  },
  is_open: {
    rules: [{ required: true, message: '请选择状态' }],
  },
  sort_num: {
    rules: [{ required: true, message: '请填写优先级' }],
  },
};

export default {
  name: 'editPostModal',
  inject: ['activityId'],
  computed: {
    title() {
      return this.id ? '修改荣耀展台榜单信息' : '添加荣耀展台榜单信息';
    },
  },
  data() {
    return {
      loading: false,
      uploading: false, // 是否正在上传图片
      shown: false,

      statusList: [
        { id: 0, name: '关闭' },
        { id: 1, name: '开启' },
      ],
      decorator,

      id: '',
      postId: '',
      stageId: '',

      head_pic_id: '',
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {});
  },
  methods: {
    show({ id, postId, stageId } = {}) {
      this.id = id;
      this.postId = postId;
      this.stageId = stageId;
      this.getBillboardDetail();

      this.shown = true;

      if (!id) {
        this.$nextTick(() => this.setDecorator({
          top_user_num: 1,
          is_open: 0,
          sort_num: 1,
        }));
      }
    },
    close() {
      this.shown = false;
    },
    reset() {
      this.id = '';
      this.postId = '';
      this.stageId = '';

      this.pic = '';
    },

    async getBillboardDetail() {
      if (!this.id) {
        return;
      }

      this.loading = true;
      const data = await getShowcasePostBillboardDetail(this.id).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取榜单详情失败');
        return;
      }
      this.setDecorator(data?.data || {});
    },

    onSubmit() {
      this.form.validateFields((error, values) => {
        if (!error) {
          const params = {
            ...values,
          };
          const pic = [...(params.head_pic_id || [])].reverse()[0] || {};
          params.head_pic_id = pic.file_resource_id || pic.originFileObj?.file_resource_id || '';

          if (this.id) {
            params.id = this.id;
          }
          params.activity_id = this.activityId;
          params.activity_module_id = this.stageId;
          params.activity_module_honour_post_id = this.postId;

          this.save(params);
        }
      });
    },
    async save(params) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await updateShowcasePostBillboard(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');

      const evt = {
        ...params,
        postId: this.postId,
        stageId: this.stageId,
      };
      this.$emit('saved', evt);
      this.close();
    },

    setDecorator(data = {}) {
      const values = {};
      Object.keys(this.decorator).forEach((key) => {
        if (data[key] !== undefined && data[key] !== null) {
          values[key] = data[key];
        }
      });

      if (data.head_pic_id) {
        values.head_pic_id = data.head_pic_id ? [{
          uid: `${data.head_pic_data.id}`,
          name: `${data.head_pic_data.down_title || data.head_pic_data.url.split('/').reverse()[0]}`,
          status: 'done',
          url: data.head_pic_data.url,
          file_resource_id: data.head_pic_data.id,
        }] : [];
      }
      this.head_pic_id = data.head_pic_data?.url || '';

      this.form.setFieldsValue(values);
    },

    beforeUpload(file) {
      const types = ['jpg', 'jpeg', 'png'];
      const type = file.type;
      const size = file.size;

      return new Promise((resolve, reject) => {
        if (types.every((t) => !type.includes(t))) {
          this.$message.info('图片仅支持 .jpg .jpeg .png 格式，请重新选择图片');
          reject('ImageTypeError');
        } else if (size > 2 * 1024 * 1024) {
          this.$message.info('图片大小不能超过 2M，请重新选择图片');
          reject('ImageSizeError');
        } else {
          resolve();
        }
      });
    },
    async customRequest(field, { file }) {
      if (this.uploading) {
        return;
      }
      this.uploading = true;

      const params = new FormData();
      params.append('file', file);
      params.append('source', 'common');

      const data = await uploadFileToPublic(params);
      if (!data || data.error_code) {
        this.$message.error(data?.message || '图片上传失败');
        return;
      }
      this.$message.success(data.message || '图片上传成功');

      this.uploading = false;
      file.url = data.data.url;
      file.file_resource_id = data.data.file_resource_id;
      this[field] = file.url;
    },
  },
};
</script>

<style scoped lang="less">
.cover-img {
  max-width: 86px;
  max-height: 86px;
}
</style>
