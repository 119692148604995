import request from '../../../../utils/Request';

// 全部模块列表
export function getStageListForSelect(params) {
  return request({
    url: '/activity/admin/config/moduleList',
    method: 'get',
    params,
  });
}

// 全部标题列表
export function getTitleListForSelect(params) {
  return request({
    url: '/activity/admin/config/activityTitleList',
    method: 'get',
    params,
  });
}
