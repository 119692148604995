<template>
  <a-form :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          class="form">
    <a-form-item label="选择课程">
      <SelectCourse v-decorator="['course_ids', decorator.course_ids]"
                    name="course_ids"
                    @change="courseChange"/>
    </a-form-item>

    <a-form-item label="组织机构">
      <app-select-org v-decorator="['org_ids', decorator.org_ids]"
                      name="org_ids"
                      :show-select-all-org="true"/>
    </a-form-item>

    <a-form-item label="店型">
      <SelectShopType v-model="shopType"
                      name="shopType"/>
    </a-form-item>

    <a-form-item label="岗位">
      <app-select-job v-decorator="['post_ids', decorator.post_ids]"
                      name="post_ids"/>
    </a-form-item>

    <a-form-item label=" " :colon="false">
      <a-button type="primary" :loading="loading" @click="exportCourseLearnInfo">
        导出课程学习明细
      </a-button>
      <a-button type="primary" :loading="loading" @click="exportCourseTaskLearnInfo">
        导出课件学习明细
      </a-button>
      <a-button @click="reset">重置</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import {
  exportCourseLearnProcess,
  exportCourseTaskLearnProcess,
} from '../api/statistics';

import SelectCourse from '../../../statistics/participation/components/SelectCourse.vue';
import SelectShopType from '../../../../components/select/SelectShopType.vue';

const decorator = {
  course_ids: {
    rules: [{ required: true, message: '请选择课程' }],
  },
  org_ids: {
    rules: [{ required: true, message: '请选择组织机构' }],
  },
  // todo 选择店型组件不支持 v-decorator ...
  // shopType: {
  //   rules: [{ required: false, message: '请选择店型' }],
  // },
  post_ids: {
    rules: [{ required: false, message: '请选择岗位' }],
  },
};

export default {
  name: 'learnProcess',
  components: {
    SelectCourse,
    SelectShopType,
  },
  data() {
    return {
      loading: false,

      decorator,

      shopType: { store_state: '1' },
      id: '',
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {});
  },
  methods: {
    setDecorator(data = {}) {
      const values = {};
      Object.keys(this.decorator).forEach((key) => {
        if (data[key] !== undefined && data[key] !== null) {
          values[key] = data[key];
        }
      });
      this.form.setFieldsValue(values);
    },

    validate() {
      return new Promise((resolve, reject) => {
        this.form.validateFields((error, values) => {
          if (!error) {
            const params = {
              ...values,
            };

            params.store_state = this.shopType?.store_state || '';
            params.store_type = this.shopType?.store_type || '';
            params.operation_state = this.shopType?.operation_state || '';
            params.store_age = this.shopType?.store_age || '';

            params.down = 1;

            delete params.shopType;

            resolve(params);
          } else {
            reject(error);
          }
        });
      });
    },

    exportCourseLearnInfo() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      this.validate().then((params) => {
        exportCourseLearnProcess(params).then(() => {
          this.$message.success('下载成功');
        }).catch((err) => {
          this.$message.error(err?.response?.message || '下载失败');
        }).finally(() => {
          this.loading = false;
        });
      }).catch(() => {
        this.loading = false;
      });
    },
    exportCourseTaskLearnInfo() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      this.validate().then((params) => {
        exportCourseTaskLearnProcess(params).then(() => {
          this.$message.success('下载成功');
        }).catch((err) => {
          this.$message.error(err?.response?.message || '下载失败');
        }).finally(() => {
          this.loading = false;
        });
      }).catch(() => {
        this.loading = false;
      });
    },

    reset() {
      const item = {};
      Object.keys(this.decorator).forEach((key) => {
        if (key === 'shopType') {
          item[key] = { store_state: '1' };
        } else {
          item[key] = '';
        }
      });
      this.shopType = { store_state: '1' };
      this.setDecorator(item);
    },

    courseChange(ids, items) {
      if (!items?.length) {
        return;
      }
      this.setDecorator({
        post_ids: Array.from(
          new Set(
            items.map((i) => i.tags).join(',').split(',').filter((i) => !!i),
          ),
        ).join(','),
      });
    },
  },
};
</script>

<style scoped lang="less">
.form {
  max-width: 800px;
}
</style>
