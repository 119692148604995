<template>
  <a-modal :visible="shown"
           :title="title"
           :confirm-loading="loading || uploading"
           :mask-closable="false"
           :destroy-on-close="true"
           :after-close="reset"
           width="600px"
           @ok="onSubmit"
           @cancel="close()">
    <a-form :form="form"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }">
      <a-form-item label="岗位">
        <app-select-job v-decorator="['post_id', decorator.post_id]"
                        name="post_id"
                        mode="default"
                        class="select-job"
                        placeholder="请选择岗位"
                        @change="jobChanged"/>
      </a-form-item>

      <a-form-item label="入口名称">
        <a-input v-decorator="['primary_title', decorator.primary_title]"
                 :allow-clear="true"
                 name="primary_title"
                 placeholder="请填写入口名称"/>
      </a-form-item>

      <a-form-item label="选择课程">
        <!--只查询下架课程-->
        <SelectCourse v-decorator="['course_id', decorator.course_id]"
                      mode="default"
                      :shelf-status="1"
                      name="course_id"
                      @change="onCourseChanged"/>
      </a-form-item>

      <a-form-item label="选择课件">
        <SelectCourseTask v-decorator="['type_value', decorator.type_value]"
                          :course-ids="courseIds"
                          :search-non-course-id="!!(id && courseIds)"
                          course-task-type="testpaper"
                          mode="default"
                          name="type_value"/>
      </a-form-item>

      <a-form-item label="状态">
        <a-radio-group v-decorator="['status', decorator.status]" name="status">
          <a-radio v-for="(item) in statusList" :key="item.id" :value="item.id">{{ item.name }}</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="开始时间">
        <a-date-picker v-decorator="['start_time', decorator.start_time]"
                       :show-time="{ format: 'HH:mm:ss', defaultValue: defaultStartTime }"
                       name="end_time"/>
      </a-form-item>
      <a-form-item label="结束时间">
        <a-date-picker v-decorator="['end_time', decorator.end_time]"
                       :show-time="{ format: 'HH:mm:ss', defaultValue: defaultEndTime }"
                       name="end_time"/>
      </a-form-item>

      <a-form-item label="优先级">
        <a-input-number v-decorator="['sort', decorator.sort]"
                        :min="0"
                        :formatter="value => `${value}`.replace(/\D*/g, '')"
                        :parser="value => `${value}`.replace(/\D*/g, '')"
                        name="sort"
                        placeholder="请填写优先级"
                        style="width: 180px;"/>
      </a-form-item>

    </a-form>
  </a-modal>
</template>

<script>
import moment from 'moment';
import {
  getExamDetail,
  updateExam,
} from '../api/config';

import SelectCourse from '../../../../components/select/SelectCourse.vue';
import SelectCourseTask from '../../../../components/select/SelectCourseTask.vue';

const decorator = {
  post_id: {
    rules: [{ required: true, message: '请选择岗位' }],
  },
  primary_title: {
    rules: [{ required: true, message: '请填写入口名称' }],
  },
  course_id: {
    rules: [{ required: true, message: '请填选择课程' }],
  },
  type_value: {
    rules: [{ required: true, message: '请填选择考试课件' }],
  },
  status: {
    rules: [{ required: true, message: '请选择状态' }],
  },
  start_time: {
    rules: [{ required: true, message: '请选择开始时间' }],
  },
  end_time: {
    rules: [{ required: true, message: '请选择结束时间' }],
  },
  sort: {
    rules: [{ required: true, message: '请填写优先级' }],
  },
};

export default {
  name: 'editExamModal',
  components: {
    SelectCourse,
    SelectCourseTask,
  },
  inject: ['activityId'],
  computed: {
    title() {
      return this.id ? '修改考试入口' : '添加考试入口';
    },
    defaultStartTime() {
      return moment().startOf('days');
    },
    defaultEndTime() {
      return moment().endOf('days');
    },
  },
  data() {
    return {
      loading: false,
      uploading: false, // 是否正在上传图片
      shown: false,

      statusList: [
        { id: 0, name: '关闭' },
        { id: 1, name: '开启' },
      ],
      decorator,

      id: '',
      stageId: '',
      record: {},

      original: {},

      courseIds: '',
      jobName: '',
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {});
  },
  methods: {
    show({ id, stageId, record } = {}) {
      this.id = id;
      this.stageId = stageId;
      this.record = record;
      this.getOpusUploadDetail();

      this.shown = true;

      if (!id) {
        this.$nextTick(() => this.setDecorator({
          status: 0,
          sort: 1,
        }));
      }
    },
    close() {
      this.shown = false;
    },
    reset() {
      this.id = '';
      this.stageId = '';
      this.courseIds = '';
      this.jobName = '';
      this.record = {};
      this.original = {};
    },

    async getOpusUploadDetail() {
      if (!this.id) {
        return;
      }

      this.loading = true;
      const data = await getExamDetail({
        id: this.id,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取阶段详情失败');
        return;
      }
      this.original = { ...(data?.data || {}) };
      this.courseIds = this.original.course_id + '';
      this.setDecorator(data?.data || {});
    },

    onSubmit() {
      this.form.validateFields((error, values) => {
        if (!error) {
          const params = {
            ...values,
            start_time: values.start_time ? Math.floor(
              values.start_time.toDate().getTime() / (1000 * 60),
            ) * 60 : '',
            end_time: values.end_time ? Math.floor(
              values.end_time.toDate().getTime() / (1000 * 60),
            ) * 60 : '',
          };

          if (this.id) {
            params.id = this.id;
          }
          params.activity_id = this.activityId;
          params.activity_module_id = this.stageId;

          this.save(params);
        }
      });
    },
    async save(params) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await updateExam(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');

      const evt = {
        ...params,
        status_name: this.statusList.filter((i) => i.id === params.status)?.[0]?.name || '',
      };
      if (this.jobName) {
        evt.post_name = this.jobName;
      }
      if (this.original?.sort !== evt.sort) {
        evt.id = undefined;
      }
      this.$emit('saved', evt);
      this.close();
    },

    setDecorator(data = {}) {
      const values = {};
      Object.keys(this.decorator).forEach((key) => {
        if (data[key] !== undefined && data[key] !== null) {
          values[key] = data[key];
        }
      });

      if (values.course_id) {
        values.course_id = values.course_id + '';
        this.coursebIds = values.course_id + '';
      }
      if (values.post_id) {
        values.post_id = values.post_id + '';
      }
      if (values.start_time) {
        values.start_time = moment(values.start_time * 1000);
      }
      if (values.end_time) {
        values.end_time = moment(values.end_time * 1000);
      }

      this.form.setFieldsValue(values);
    },

    jobChanged(ids, items) {
      this.jobName = items?.[0]?.name || '';

      const primaryTitle = this.record.children.filter(
        (i) => +i.post_id === +ids,
      )?.[0]?.primary_title || '';
      if (primaryTitle) {
        this.setDecorator({ primary_title: primaryTitle });
      }
    },

    onCourseChanged(id) {
      this.courseIds = id;
    },
  },
};
</script>

<style scoped lang="less">
.select-job {
  transform: translateY(5px);
}
</style>
