<template>
  <SelectWithPager placeholder="请选择课件"
                   v-bind="$attrs"
                   v-model="selectedIds"
                   :mode="mode"
                   :options="{id: 'id', name: 'title'}"
                   :list="selectList"
                   :pager="{
                     page: page,
                     perPage: perPage,
                     total: total,
                   }"
                   @search="onSearch"
                   @change="onChange"
                   @reach-bottom="onReachBottom"/>
</template>

<script>
import SelectWithPager from './SelectWithPager.vue';
import { getSelectList } from './api';

export default {
  name: 'SelectCourseTask',
  components: {
    SelectWithPager,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String },
    courseIds: { type: String, default: '' },
    searchNonCourseId: { type: Boolean, default: false },
    courseTaskType: {
      type: String,
      default: '',
      validator: (type) => [
        'testpaper',
        'video',
        'pdf',
      ].includes(type),
    },
    mode: { type: String, default: 'multiple' },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.selectedIds = this.value;
        this.setSelectedItems();
      },
    },
    selectList: {
      immediate: true,
      deep: true,
      handler() {
        this.setSelectedItems();
      },
    },
    courseIds: {
      immediate: true,
      handler(val, oldVal) {
        if (oldVal && this.selectedIds) {
          this.selectedIds = '';
          this.selectedItems = [];
          this.$emit('change', '', []);
        }

        this.getList(1);
      },
    },
    searchNonCourseId: {
      immediate: false,
      handler(val) {
        if (val) {
          this.getList(1);
        }
      },
    },
  },
  data() {
    return {
      selectedIds: '',
      selectedItems: [],

      page: 1,
      perPage: 50,
      total: 0,

      filterTxt: '',
      selectList: [],
    };
  },
  created() {
    this.getList(1);
  },
  methods: {
    setSelectedItems() {
      if (this.selectList?.length && this.selectedIds) {
        this.selectedItems = this.selectList.filter(
          (i) => this.selectedIds.split(',').filter(
            (i) => !!i,
          ).map(
            (i) => +i,
          ).includes(i.id),
        );
      }
    },

    async getList(page) {
      if (!this.searchNonCourseId && !this.courseIds) {
        this.page = 1;
        this.total = 0;
        this.selectList = [];
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await getSelectList({
        url: '/public/conf/course/show-course',
        params: {
          page,
          per_page: this.perPage,
          title: this.filterTxt,
          course_id: this.courseIds,
          course_ids: this.courseIds,
          task_type: this.courseTaskType,
        },
      }).finally(() => {
        this.loading = false;
      });

      this.page += 1;
      const result = (data?.data || []).reduce(
        (prev, curr) => [...prev, ...(curr.task_list || [])],
        [],
      );
      const selectList = page > 1 ? (this.selectList.concat(...result)) : result;

      this.selectedItems.forEach((item) => {
        if (selectList.every((i) => i.id !== item.id)) {
          selectList.unshift(item);
        }
      });

      this.selectList = selectList;
      this.total = data?.meta?.pagination?.total || 0;
    },

    onSearch(evt) {
      this.filterTxt = evt;
      this.getList(1);
    },
    onChange(ids, items) {
      this.selectedIds = ids;
      this.selectedItems = items;
      this.$emit('change', ids, items);
    },
    onReachBottom() {
      this.getList(this.page + 1);
    },

  },
};
</script>
